import styled, { keyframes } from 'styled-components';
import sizing from '../../designSystem/sizing';
import { Image } from '../../components/Image/styles';
import { Text } from '../../components/Text/styles';
import colors from '../../designSystem/colors';
import { MonospacedProps, PageProps, PositionProps } from './types';

export const PageContainer = styled.div<PageProps>`
    display: block;
    position: relative;
    overflow: ${({ disableScroll }) => (disableScroll ? 'hidden' : 'auto')};
    width: 100%;
`;

export const ContentWrapper = styled.div`
    margin: 0 ${sizing.SPACING.STANDARD} 0 ${sizing.SPACING.STANDARD};
    padding-bottom: ${sizing.SPACING.DOUBLE_LARGE};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const BlockContatiner = styled.div`
    display: block;
`;

export const DesktopLeftAlignedContentWrapper = styled.div`
    margin: 0 ${sizing.SPACING.STANDARD} 0 ${sizing.SPACING.STANDARD};
    padding-bottom: ${sizing.SPACING.DOUBLE_LARGE};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        align-items: flex-start;
    }
`;

export const SupplementaryImage = styled.img<PositionProps>`
    display: none;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: block;
        position: absolute;
        ${({ top }) => (top ? `top: ${top};` : '')}
        ${({ right }) => (right ? `right: ${right};` : '')}
        ${({ bottom }) => (bottom ? `bottom: ${bottom};` : '')}
        ${({ left }) => (left ? `left: ${left};` : '')}
    }
`;

export const SideBySide = styled.div`
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > * {
            width: min-content;
            min-width: 40%;
        }
    }
`;

export const LeftAlignedContentWrapper = styled(ContentWrapper)`
    align-items: flex-start;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        align-items: center;
    }
`;

export const Example = styled(Image)`
    width: 100%;
    box-shadow: 0px 24px 68px 0px rgba(47, 48, 55, 0.05);

    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: none;
    }
`;

export const DesktopExample = styled(Image)`
    width: 100%;
    box-shadow: 0px 24px 68px 0px rgba(47, 48, 55, 0.05);
    display: none;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: block;
    }
`;

const CenteredWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HeaderWrapper = styled(CenteredWrapper)`
    margin: ${sizing.SPACING.DOUBLE_LARGE} 0 ${sizing.SPACING.LARGE} 0;
    display: block;
`;

export const TextWrapper = styled(CenteredWrapper)``;

export const ButtonWrapper = styled(CenteredWrapper)`
    margin: ${sizing.SPACING.DOUBLE_LARGE} 0;
`;

export const MonospacedTextWrapper = styled(CenteredWrapper)<MonospacedProps>`
    margin: ${sizing.SPACING.DOUBLE_LARGE} 0;

    & > p {
        font-family: 'NeueMontreal-Mono', monospace;
        font-size: ${sizing.TEXT.SMALL};
        color: ${({ dark }) =>
            dark ? colors.UI.TEXT.DARKEST_GREY : colors.UI.TEXT.DARK_GREY};
    }
`;

export const NameText = styled(Text)`
    color: ${colors.UI.TEXT.GREY};
    text-align: center;
`;

export const Testimonial = styled(Text)`
    margin: ${sizing.SPACING.LARGE} ${sizing.SPACING.STANDARD};
    font-size: ${sizing.TEXT.H2};
    text-align: center;
`;

export const Container = styled.div`
    display: block;
    width: 100%;
    height: min-content;
    background: ${colors.UI.BACKGROUND.STANDARD};
    margin-top: 0;
`;

export const HeaderWithGradientBorderBottom = styled(HeaderWrapper)`
    margin: ${sizing.SPACING.DOUBLE_LARGE} ${sizing.SPACING.STANDARD}
        ${sizing.SPACING.DOUBLE_LARGE} ${sizing.SPACING.STANDARD};
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
            to right,
            transparent,
            ${colors.GRID.LINE},
            transparent
        );
    }
`;

export const HowItWorksSubheaderText = styled(Text)`
    font-weight: 500;
    font-size: ${sizing.TEXT.LARGER};
    text-align: center;
    margin: ${sizing.SPACING.LARGE};
`;

export const HowItWorksText = styled(Text)`
    font-size: ${sizing.TEXT.STANDARD};
    text-align: center;
`;

export const PsuedoElementContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${sizing.SPACING.DOUBLE_LARGE};
`;

export const HowItWorksTextWithMargin = styled(HowItWorksText)`
    margin-top: ${sizing.SPACING.LARGE};
`;

export const TextWithPseudoContainer = styled.div`
    padding: 0 0 ${sizing.SPACING.LARGE} 0;
    position: relative;
    width: 100%;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
            to right,
            transparent,
            ${colors.GRID.LINE},
            transparent
        );
    }
`;

export const ImageWithPseudoContainer = styled(TextWithPseudoContainer)`
    display: flex;
    justify-content: center;
`;

export const IconHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: min-content;
    margin: ${sizing.SPACING.LARGE} 0;

    & :first-child {
        margin-right: ${sizing.SPACING.STANDARD};
    }

    & :last-child {
        margin: 0;
    }
`;

export const ElementWithPseudoContainer = styled.div`
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
            to right,
            transparent,
            ${colors.GRID.LINE},
            transparent
        );
    }
`;

export const QuestionsWrapper = styled.div`
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & > div {
            margin: ${sizing.SPACING.SMALL};
        }
    }
`;

export const LeftAlignedCard = styled.div`
    display: block;
    background-color: ${colors.UI.BACKGROUND.STANDARD};
    border-radius: ${sizing.RADIUS.LARGE};
    padding: ${sizing.SPACING.LARGE};
    margin: ${sizing.SPACING.STANDARD} 0;
    & > * {
        text-align: left;
        margin-left: 0;
    }

    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        width: 384px;
        display: flex;
        align-items: center;
        flex-direction: column;
        & > * {
            margin: 0 0 ${sizing.SPACING.STANDARD} 0;
            text-align: center;
        }
    }
`;

export const QuestionText = styled(Text)`
    color: ${colors.UI.TEXT.STANDARD};
    font-weight: 400;
`;

export const QuestionHeader = styled(Text)`
    color: ${colors.UI.TEXT.DARKEST_GREY};
    font-weight: 500;
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Footer = styled.div`
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        padding: 0 ${sizing.SPACING.STANDARD};
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        & > p {
            padding: 0;
        }
    }
`;

export const ReverseGradientContainer = styled.div`
    background: linear-gradient(
        to bottom,
        ${colors.UI.BACKGROUND.STANDARD},
        transparent
    );

    & > :first-child {
        margin-top: 0;
        padding-top: ${sizing.SPACING.DOUBLE_LARGE};
    }

    & > :last-child {
        padding-bottom: 0;
    }
`;

export const FooterButtonWrapper = styled.div`
    display: flex;
    padding: ${sizing.SPACING.LARGE};

    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        padding: 0;
    }
`;

export const Copywrite = styled(Text)`
    padding-bottom: ${sizing.SPACING.DOUBLE_LARGE};
`;

export const DesktopWrapper = styled.div`
    position: relative;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        max-width: 1400px;
        margin: auto;
        padding: ${sizing.SPACING.DOUBLE_LARGE} 0;
    }
`;

export const TallDesktopWrapper = styled(DesktopWrapper)`
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        padding: ${sizing.SPACING.OCTO_LARGE} 0;
    }
`;

export const DesktopNavbarWrapper = styled(DesktopWrapper)`
    padding: 0 !important;
`;

export const CardContainer = styled.div`
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const Cards = styled.div`
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const CardInfoContainer = styled.div`
    margin-top: ${sizing.SPACING.DOUBLE_LARGE};
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        margin-top: 0;
    }
    & > div > div {
        margin-top: ${sizing.SPACING.LARGE};
    }
`;

export const Spacer = styled.div`
    height: ${sizing.SPACING.DOUBLE_LARGE};
`;
