import React from 'react';

import { SemiTransparentContainerProps } from './types';
import { SemiTransparentDiv } from './styles';

const SemiTransparentContainer: React.FC<SemiTransparentContainerProps> = ({
    children,
    backgroundColor,
}) => {
    return (
        <SemiTransparentDiv backgroundColor={backgroundColor}>
            {children}
        </SemiTransparentDiv>
    );
};

export default SemiTransparentContainer;
