import styled from 'styled-components';
import colors from '../../designSystem/colors';
import { AnimationState } from './types';

export const GridContainer = styled.div<{
    rows: number;
    cols: number;
    cellSize: number;
    borderWidth: number;
}>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    overflow: hidden;
    display: grid;
    grid-template-rows: repeat(
        ${(props) => props.rows},
        ${(props) => props.cellSize}px
    );
    grid-template-columns: repeat(
        ${(props) => props.cols},
        ${(props) => props.cellSize}px
    );
    gap: ${(props) => props.borderWidth}px;
    background-color: ${colors.GRID.BACKGROUND};
    z-index: -100;
`;

export const Cell = styled.div<{
    isColored: boolean;
    isBlank: boolean;
    borderColor: string;
    borderWidth: number;
    coloredSquareColor: string;
    animationState: AnimationState;
    transitionDuration: string;
}>`
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.isColored ? props.coloredSquareColor : 'transparent'};
    border: ${(props) =>
        props.isColored
            ? `${props.borderWidth}px solid ${props.borderColor}`
            : props.isBlank
              ? 'transparent'
              : `${props.borderWidth}px solid ${props.borderColor}`};
    transition:
        background-color ${({ transitionDuration }) => transitionDuration}
            ease-in-out,
        border ${({ transitionDuration }) => transitionDuration} ease-in-out,
        border-color ${({ transitionDuration }) => transitionDuration} ease;
`;

const hexToRgb = (hex: string): string => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
};
