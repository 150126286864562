// Rem values calculated from base font size of 14px

export default {
    SPACING: {
        SMALLEST: '0.3rem',
        SMALL: '0.571rem', //8px
        STANDARD: '0.857rem', //12px
        LARGE: '1.143rem', // 16px
        DOUBLE_LARGE: '2.286rem',
        QUAD_LARGE: '4.572rem',
        OCTO_LARGE: '9.144rem',
    },
    BREAKPOINT: {
        MOBILE_MAX: '768px',
    },
    RADIUS: {
        MEDIUM: '0.428571rem', //6px
        LARGE: '0.837142rem', // 12px
    },
    ICON: {
        HEIGHT: {
            STANDARD: '16px',
            LARGE: '20px',
            LARGER: '36px',
        },
    },
    TEXT: {
        H2: '20px',
        SMALL: '14px',
        STANDARD: '16px',
        LARGE: '18px',
        LARGER: '20px',
        H1: '52px',
        DESKTOP: {
            H1: '80px',
            H2: '48px',
            H2b: '60px',
        },
    },
};
