import React from 'react';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { ScrollAnimationProps } from './types';
import { Animation } from '../styles';

const ScrollAnimation: React.FC<ScrollAnimationProps> = ({
    children,
    delay = 0,
    flex,
}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
        once: true,
        margin: '-50px 0px',
    });

    return (
        <Animation
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, ease: 'easeOut', delay: delay }}
            flex={flex}
        >
            {children}
        </Animation>
    );
};

export default ScrollAnimation;
