import React from 'react';
import { GradientDiv } from './styles';
import { GradientContainerProps } from './types';

const GradientContainer: React.FC<GradientContainerProps> = ({
    children,
    backgroundColor,
}) => {
    return (
        <GradientDiv backgroundColor={backgroundColor}>{children}</GradientDiv>
    );
};

export default GradientContainer;
