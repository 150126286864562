import styled from 'styled-components';
import sizing from '../../designSystem/sizing';
import colors from '../../designSystem/colors';
import { NavbarContainerProps } from './types';

export const NavbarContainer = styled.div<NavbarContainerProps>`
    background-color: ${({ navbarMenuOpen }) =>
        navbarMenuOpen ? colors.UI.BACKGROUND.STANDARD : ''};
    & > div {
        margin: 0;
    }
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 ${sizing.SPACING.STANDARD};
    padding: ${sizing.SPACING.STANDARD} 0 ${sizing.SPACING.STANDARD};
`;

export const NavbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const LeftSide = styled.div`
    display: block;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: none;
    }
`;

export const DesktopLeftSide = styled.div`
    display: none;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: block;
        display: flex;
        align-items: center;

        & > :first-child {
            margin-right: ${sizing.SPACING.STANDARD};
        }
    }
`;
