import React from 'react';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { ScrollAnimationProps } from './types';
import { Animation } from '../styles';

const WipeFromLeft: React.FC<ScrollAnimationProps> = ({
    children,
    delay = 0,
}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
        once: true,
        margin: '-50px 0px', // Trigger when the element is 50px inside the viewport
    });

    return (
        <Animation
            ref={ref}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }} // Fully clipped
            animate={
                isInView
                    ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } // Reveals from left to right
                    : {}
            }
            transition={{ duration: 0.6, ease: 'easeOut', delay: delay }}
            style={{ overflow: 'hidden' }}
        >
            {children}
        </Animation>
    );
};

export default WipeFromLeft;
