export default {
    GRID: {
        LINE: '#EBECF1',
        BACKGROUND: '#F6F7FC',
        HIGHLIGHT: '#F1F3F7',
        EMPTY: '#F6F7FC',
    },
    UI: {
        BACKGROUND: {
            STANDARD: '#F6F7FC',
            SECONDARY: '#C6D6FF4D',
            ACTIVE: '#ECEEFE',
            SELECTED: '#F6F6FE',
            ELEMENT: '#FCFCFD',
            MODAL: '#18181B',
            ACCENT: '#FBFCFF',
            DANGER: '#FAE7E7',
            WHITE: '#FFFFFF',
        },
        BORDER: {
            STANDARD: '#DFE1E6',
            SELECTED: '#4455F3',
            ACCENT: '#495AF3',
            DANGER: '#EA9999',
        },
        TEXT: {
            STANDARD: '#3F3F46',
            SECONDARY: '#FFFFFF',
            LIGHT: '#71717A',
            GREY: '#8B8B8D',
            DARK_GREY: '#87878B',
            DARKEST_GREY: '#18181B',
            ACTIVE: '#4456F3',
            DANGER: '#CA0607',
        },
        BUTTON: {
            PRIMARY: {
                BACKGROUND: '#FFFFFF',
                TEXT: '#3F3F46',
                HOVER: '#DFE1E6',
            },
            SECONDARY: {
                BACKGROUND: '#4455F3',
                TEXT: '#FFFFFF',
                HOVER: '#DFE1E6',
            },
            DANGER: {
                BACKGROUND: '#D32F2F',
                TEXT: '#FFFFFF',
                HOVER: '#AE3434',
            },
        },
    },
};

