import React from 'react';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { ScrollAnimationProps } from './types';

const FadeIn: React.FC<ScrollAnimationProps> = ({
    children,
    delay = 0,
}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
        once: true,
        margin: '-250px 0px',
    });

    return (
        <motion.div
            ref={ref}
            initial={{
                opacity: 0,
            }}
            animate={
                isInView
                    ? {
                          opacity: 1,
                      }
                    : {}
            }
            transition={{
                duration: 1.8, // Duration for fade-in effect
                ease: 'easeInOut',
                delay: delay,
            }}
        >
            {children}
        </motion.div>
    );
};

export default FadeIn;