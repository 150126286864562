import styled from 'styled-components';
import colors from '../../designSystem/colors';
import sizing from '../../designSystem/sizing';

export const BurgerContainer = styled.div`
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: none;
    }
`;

export const MenuOptionsContainer = styled.div`
    padding-top: ${sizing.SPACING.LARGE};
    position: absolute;
    display: block;

    left: 0;
    background-color: ${colors.UI.BACKGROUND.STANDARD};
    width: 100vw;
    height: 100vh;
    z-index: 100;
`;

export const CallButtonWrapper = styled.div`
    display: flex;
    margin: 0 ${sizing.SPACING.STANDARD};
    border-top: 1px solid ${colors.UI.BORDER.STANDARD};
    padding-top: ${sizing.SPACING.DOUBLE_LARGE};
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        padding: 0;
        border-top: 0;
    }
`;

export const ButtonWrapper = styled.div`
    padding-bottom: ${sizing.SPACING.DOUBLE_LARGE};
    border-top: 1px solid ${colors.UI.BORDER.STANDARD};
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: flex;
        padding: 0;
        border: 0;
        & > :last-child {
            border-right: 1px solid ${colors.UI.BORDER.STANDARD};
            border-radius: 0;
        }
    }
`;

export const DesktopContainer = styled.div`
    display: none;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;
