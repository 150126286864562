import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';

import Home from "./pages/Home";
import Careers from "./Careers";

const App = () => { 
  return (
    <MantineProvider theme={{ /* your theme settings */ }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          {/* <Route path="/careers" element={ <Careers /> } /> */}
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  )
};

export default App;
