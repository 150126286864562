import React, { useState } from 'react';
import { NavbarProps } from './types';
import Coverflow from '../../assets/icons/Coverflow.svg';
import { Icon } from '../Icon/styles';
import {
    DesktopLeftSide,
    LeftSide,
    NavbarContainer,
    NavbarWrapper,
} from './styles';
import BurgerMenu from '../BurgerMenu';
import Button from '../Button';
import CoverflowIcon from '../../assets/icons/Icon.svg';
import sizing from '../../designSystem/sizing';

const Navbar: React.FC<NavbarProps> = ({ callback }) => {
    const [navbarMenuOpen, setNavbarMenuOpen] = useState<boolean>(false);

    const handleCallback = (value: boolean) => {
        setNavbarMenuOpen(value);
        callback(value);
    };
    return (
        <NavbarContainer navbarMenuOpen={navbarMenuOpen}>
            <NavbarWrapper>
                <DesktopLeftSide>
                    <Button
                        iconLeft={CoverflowIcon}
                        handleClick={() => {}}
                        $secondary
                        iconSize={sizing.ICON.HEIGHT.LARGER}
                    />
                    <Icon src={Coverflow} alt="Coverflow Icon" />
                </DesktopLeftSide>
                <LeftSide>
                    <Icon src={Coverflow} alt="Coverflow Icon" />
                </LeftSide>
                <BurgerMenu callback={handleCallback} />
            </NavbarWrapper>
        </NavbarContainer>
    );
};

export default Navbar;
