import styled from 'styled-components';
import { GradientDivProps } from './types';
import sizing from '../../designSystem/sizing';

export const GradientDiv = styled.div<GradientDivProps>`
    display: block;
    width: 100%;
    height: min-content;
    background: linear-gradient(
        to bottom,
        transparent,
        ${({ backgroundColor }) => backgroundColor}
    );
`;
