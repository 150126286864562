import styled, { css, keyframes } from 'styled-components';
import SIZING from '../../designSystem/sizing';
import COLORS from '../../designSystem/colors';
import { ButtonStyleProps } from './types';
import { Icon } from '../Icon/styles';

// Keyframes for animations
const borderPulse = keyframes`
    0%, 100% {
        border-color: #4caf50; /* Start and end color */
    }
    50% {
        border-color: #81c784; /* Pulsing color */
    }
`;

const buttonGrow = keyframes`
    0% {
        transform: scale(1);
    }
    80% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1.2);
    }
`;

// Styled Components
export const ButtonContainer = styled.button<ButtonStyleProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: ${SIZING.RADIUS.MEDIUM};
    height: min-content;
    padding: ${({ $square }) =>
        $square
            ? `${SIZING.SPACING.STANDARD} auto`
            : `${SIZING.SPACING.SMALL} ${SIZING.SPACING.STANDARD}`};
    vertical-align: middle;
    border: ${({ $borderless, $secondary }) =>
        $borderless
            ? 'none'
            : `1px solid ${$secondary ? '#8891E2' : COLORS.UI.BORDER.STANDARD}`};
    background-color: ${({ $secondary, $background }) =>
        $background
            ? $background
            : $secondary
              ? 'transparent'
              : COLORS.UI.BUTTON.PRIMARY.BACKGROUND};
    ${({ $secondary }) =>
        $secondary &&
        css`
            background: linear-gradient(0deg, #4455f3, #6270f5);
        `}
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '80%' : '100%')};
    width: ${({ $grow }) => ($grow ? '100%' : 'auto')};
    white-space: nowrap;
    aspect-ratio: ${({ $square }) => ($square ? '1 / 1' : 'auto')};
    transition: background-color 0.1s;

    &:hover {
        opacity: ${({ $secondary }) => ($secondary ? '100%' : '80%')};
        transform: scale(1.05);
        transition: transform 0.3s ease-in-out;

        ${({ $secondary }) =>
            $secondary &&
            css`
                animation: ${buttonGrow} 2s ease-out forwards;
                animation-fill-mode: forwards;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 5px;
                    animation: ${borderPulse} 1.5s ease-in-out infinite;
                }
            `}
    }

    &:not(:hover) {
        ${({ $secondary }) =>
            $secondary &&
            css`
                animation-direction: reverse;

                &::after {
                    animation-direction: reverse;
                }

                span {
                    animation-direction: reverse;
                }
            `}
    }

    :first-child {
        margin-left: 0;
    }
    :last-child {
        margin-right: 0;
    }

    ${({ $square }) =>
        $square &&
        css`
            img {
                margin: auto !important;
            }
        `}
`;

export const Text = styled.span<ButtonStyleProps>`
    font-weight: ${({ $bold }) => ($bold ? '500' : '400')};
    color: ${({ $secondary, $color }) =>
        $secondary
            ? COLORS.UI.TEXT.SECONDARY
            : $color || COLORS.UI.TEXT.STANDARD};
    ${({ $grow, $center }) =>
        $grow &&
        css`
            text-align: ${$center ? 'center' : 'left'};
            flex: 1;
        `}
`;

export const IconLeft = styled(Icon)`
    margin-right: ${SIZING.SPACING.SMALL};
`;

export const IconRight = styled(Icon)`
    margin-left: ${SIZING.SPACING.SMALL};
`;
