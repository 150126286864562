import React, { useEffect, useState } from 'react';
import { HomeProps } from './types';
import Navbar from '../../components/Navbar';
import Grid from '../../components/Grid';
import colors from '../../designSystem/colors';
import GradientContainer from '../../components/GradientContainer';
import {
    ContentWrapper,
    Example,
    HeaderWrapper,
    PageContainer,
    TextWrapper,
    ButtonWrapper,
    MonospacedTextWrapper,
    NameText,
    Testimonial,
    Container,
    HeaderWithGradientBorderBottom,
    HowItWorksText,
    HowItWorksSubheaderText,
    PsuedoElementContainer,
    HowItWorksTextWithMargin,
    ElementWithPseudoContainer,
    IconHeaderWrapper,
    TextWithPseudoContainer,
    LeftAlignedCard,
    LeftAlignedContentWrapper,
    FooterContainer,
    ReverseGradientContainer,
    FooterButtonWrapper,
    Copywrite,
    DesktopExample,
    DesktopWrapper,
    CardContainer,
    Cards,
    QuestionsWrapper,
    Footer,
    Spacer,
    SideBySide,
    DesktopLeftAlignedContentWrapper,
    ImageWithPseudoContainer,
    DesktopNavbarWrapper,
    SupplementaryImage,
    TallDesktopWrapper,
    CardInfoContainer,
    BlockContatiner,
} from './styles';
import {
    Header,
    MediumHeader,
    SubHeader,
} from '../../components/Header/styles';
import Button from '../../components/Button';
import { Text } from '../../components/Text/styles';
import {
    DesktopImage,
    Image,
    MobileImage,
} from '../../components/Image/styles';
import dashboardMobileImg from '../../assets/images/Dashboard_Mobile.png';
import TrustedBy from '../../assets/images/Trusted_By.png';
import TrustedByDesktop from '../../assets/images/Trusted_By_Desktop.svg';
import SemiTransparentContainer from '../../components/SemiTransparentContainer';
import Card from '../../components/Card';
import James from '../../assets/images/James.png';
import Lauren from '../../assets/images/Lauren.png';
import David from '../../assets/images/David.png';
import IntegratesWith_Mobile from '../../assets/images/IntegratesWith_Mobile.png';
import IntegratesWith_Desktop from '../../assets/images/IntegratesWith_Desktop.svg';
import Stamp from '../../assets/images/Stamp.svg';
import { Icon } from '../../components/Icon/styles';
import Upload from '../../assets/icons/Upload.svg';
import Sparkles from '../../assets/icons/Sparkles.svg';
import Refresh from '../../assets/icons/Refresh.svg';
import Shield from '../../assets/icons/Shield.svg';
import PolicyAnalysis from '../../assets/images/Policy_Analysis.png';
import PolicyAnalysis_Desktop from '../../assets/images/Policy_Analysis_Desktop.svg';
import PolicyAnalysis_2_Desktop from '../../assets/images/Policy_Analysis_2_Desktop.svg';
import PolicyAnalysis_3_Desktop from '../../assets/images/Policy_Analysis_3_Desktop.svg';
import GenerateProposal from '../../assets/images/Generate_Proposal.png';
import GenerateProposalDesktop from '../../assets/images/Generate_Proposal_Desktop.svg';
import Toast from '../../assets/images/Toast.svg';
import GenerateProposal_2_Desktop from '../../assets/images/Generate_Proposal_2_Desktop.svg';
import Search from '../../assets/icons/Search.svg';
import Highlighter from '../../assets/icons/Highlighter.svg';
import Book from '../../assets/icons/Book.svg';
import Flag from '../../assets/icons/Flag.svg';
import Scales from '../../assets/icons/Scales.svg';
import File from '../../assets/icons/File.svg';
import Lock from '../../assets/icons/Lock.svg';
import List from '../../assets/icons/List.svg';
import Download from '../../assets/icons/Download.svg';
import AMSUpdates from '../../assets/images/AMS_Updates.png';
import AMSUpdates_Desktop from '../../assets/images/AMS_Updates_Desktop.svg';
import AMSUpdates_2_Desktop from '../../assets/images/AMS_Updates_2_Desktop.svg';
import AMSUpdates_3_Desktop from '../../assets/images/AMS_Updates_3_Desktop.svg';
import QuestionBlue from '../../assets/icons/Question_Blue.svg';
import QuestionGreen from '../../assets/icons/Question_Green.svg';
import QuestionPurple from '../../assets/icons/Question_Purple.svg';
import QuestionOrange from '../../assets/icons/Question_Orange.svg';
import QuestionPink from '../../assets/icons/Question_Pink.svg';
import dashboardDesktop from '../../assets/images/Dashboard_Desktop.svg';
import { Element } from 'react-scroll';
import Links from '../../constants/links';
import ScrollAnimation from '../../animations/ScrollAnimation';
import WipeFromLeft from '../../animations/WipeAnimation';
import ZoomInElastic from '../../animations/ZoomInElastic';
import FallingZoom from '../../animations/FallingZoom';
import FadeIn from '../../animations/FadeIn';

const Home: React.FC<HomeProps> = () => {
    const handleBookCall = () => {
        window.open(Links.BOOK_CALL, '_blank', 'noopener,noreferrer');
    };

    const [navbarMenuOpen, setNavbarMenuOpen] = useState<boolean>(false);

    const handleCallback = (value: boolean) => {
        setNavbarMenuOpen(value);
    };

    const useScrollLock = (lock) => {
        useEffect(() => {
            const preventScroll = (e) => {
                e.preventDefault();
            };

            if (lock) {
                document.body.style.overflow = 'hidden';
                document.addEventListener('touchmove', preventScroll, {
                    passive: false,
                });
            } else {
                document.body.style.overflow = '';
                document.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                document.body.style.overflow = '';
                document.removeEventListener('touchmove', preventScroll);
            };
        }, [lock]);
    };

    useScrollLock(navbarMenuOpen);

    return (
        <PageContainer disableScroll={navbarMenuOpen}>
            <Grid
                cellSize={50}
                borderColor={colors.GRID.LINE}
                borderWidth={2}
                colorFrequency={0.1}
                blankFrequency={0.1}
                coloredSquareColor={colors.GRID.HIGHLIGHT}
            />
            <ReverseGradientContainer>
                <DesktopNavbarWrapper>
                    <Navbar callback={handleCallback} />
                </DesktopNavbarWrapper>
            </ReverseGradientContainer>
            <GradientContainer backgroundColor={colors.UI.BACKGROUND.STANDARD}>
                <DesktopWrapper>
                    <ContentWrapper>
                        <FadeIn>
                            <HeaderWrapper>
                                <Header center>Compare quotes on easy mode.</Header>
                            </HeaderWrapper>
                            <TextWrapper>
                                <Text center>
                                    <div style={{ fontSize: "18px", color: "#3F3F46" }}>
                                        Generate spreadsheets and proposals for your
                                        policies in under 10 minutes.
                                    </div>
                                </Text>
                            </TextWrapper>
                            <ButtonWrapper>
                                <Button
                                    handleClick={handleBookCall}
                                    $secondary
                                    text="Book Call"
                                />
                            </ButtonWrapper>
                        </FadeIn>
                        <FadeIn>
                            <Example
                                src={dashboardMobileImg}
                                alt="Coverflow Example"
                            />
                            <DesktopExample
                                src={dashboardDesktop}
                                alt="Coverflow Example"
                            />
                        </FadeIn>
                        <WipeFromLeft>
                            <BlockContatiner>
                                <MonospacedTextWrapper>
                                    <Text center>
                                        TRUSTED BY LEADING INSURANCE BROKERAGES
                                    </Text>
                                </MonospacedTextWrapper>
                                <MobileImage src={TrustedBy} alt="Trusted By" />
                                <DesktopImage
                                    src={TrustedByDesktop}
                                    alt="Trusted By"
                                />
                            </BlockContatiner>
                        </WipeFromLeft>
                    </ContentWrapper>
                </DesktopWrapper>
            </GradientContainer>
            <SemiTransparentContainer
                backgroundColor={colors.UI.BACKGROUND.SECONDARY}
            >
                <DesktopWrapper>
                    <ContentWrapper>
                        <HeaderWrapper>
                            <WipeFromLeft>
                                <SubHeader center>
                                    People love Coverflow
                                </SubHeader>
                            </WipeFromLeft>
                        </HeaderWrapper>
                        <WipeFromLeft delay={0.2}>
                            <TextWrapper>
                                <Text center>
                                    Discover how forward-thinking brokerages are
                                    using AI to work smarter, not harder.
                                </Text>
                            </TextWrapper>
                        </WipeFromLeft>
                        <CardContainer>
                            <ScrollAnimation delay={0.1} flex>
                                <Card>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                                        {/*<Image
                                            src={James}
                                            alt="James Testimonial"
                                        />*/}
                                        <Testimonial>
                                            Coverflow saves me hours of work every week.
                                        </Testimonial>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                            <NameText>CARMEN D.</NameText>
                                            <NameText>Commercial Lines Account Manager</NameText>
                                            <NameText>Armstrong & Associates</NameText>
                                        </div>
                                    </div>
                                </Card>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.2} flex>
                                <Card>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                                    {/*<Image
                                        src={Lauren}
                                        alt="Lauren Testimonial"
                                    />*/}
                                    <Testimonial>
                                        I love the detailed endorsement descriptions—they help me know exactly what to expect. 
                                    </Testimonial>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                        <NameText>DENISE D.</NameText>
                                        <NameText>Account Executive</NameText>
                                        <NameText>Bridgepoint Risk</NameText>
                                    </div>
                                    </div>
                                </Card>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.3} flex>
                                <Card>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                                    {/*<Image
                                        src={David}
                                        alt="David Testimonial"
                                    />*/}
                                    <Testimonial>
                                        There's no way I would get to everything without it.
                                    </Testimonial>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                        <NameText>AMY S.</NameText>
                                        <NameText>Senior Account Manager</NameText>
                                        <NameText>Portal Insurance</NameText>
                                    </div>
                                    </div>
                                </Card>
                            </ScrollAnimation>
                        </CardContainer>
                        <MonospacedTextWrapper>
                            <Text center>INTEGRATES WITH</Text>
                        </MonospacedTextWrapper>
                        <WipeFromLeft>
                            <MobileImage
                                src={IntegratesWith_Mobile}
                                alt="Integrates With"
                            />
                            <DesktopImage
                                src={IntegratesWith_Desktop}
                                alt="Integrates With"
                            />
                        </WipeFromLeft>
                    </ContentWrapper>
                </DesktopWrapper>
            </SemiTransparentContainer>
            <Container>
                <DesktopWrapper>
                    <ContentWrapper>
                        <PsuedoElementContainer>
                            <Image src={Stamp} alt="AICPA SOC Stamp" />
                            <WipeFromLeft>
                                <HeaderWithGradientBorderBottom>
                                    <MediumHeader center>
                                        Coverflow is an AI-powered insurance
                                        platform that handles policy checking to
                                        AMS updates, so your team can focus on
                                        growing the business.
                                    </MediumHeader>
                                </HeaderWithGradientBorderBottom>
                            </WipeFromLeft>
                        </PsuedoElementContainer>
                        <Element name="How">
                            <WipeFromLeft>
                                <HeaderWrapper>
                                    <SubHeader center>How Coverflow Works</SubHeader>
                                </HeaderWrapper>
                            </WipeFromLeft>
                        </Element>
                        <ElementWithPseudoContainer>
                            <Cards>
                                <CardContainer>
                                    <ScrollAnimation delay={0.1}>
                                        <Card>
                                            <Icon
                                                src={Upload}
                                                alt="Upload Icon"
                                            />
                                            <HowItWorksSubheaderText>
                                                1. Upload Your Policies
                                            </HowItWorksSubheaderText>
                                            <HowItWorksText>
                                                Just drag and drop. Coverflow
                                                automatically identifies policy
                                                types and organizes them for
                                                processing.
                                            </HowItWorksText>
                                        </Card>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={0.2}>
                                        <Card>
                                            <Icon
                                                src={Sparkles}
                                                alt="Analyze Icon"
                                            />
                                            <HowItWorksSubheaderText>
                                                2. Analyze Comprehensively
                                            </HowItWorksSubheaderText>
                                            <HowItWorksText>
                                                Our AI extracts key info and
                                                flags discrepancies. Compare
                                                coverage, exclusions, schedules,
                                                or any line-specific feature you
                                                can think of.
                                            </HowItWorksText>
                                        </Card>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={0.3}>
                                        <Card>
                                            <Icon
                                                src={Refresh}
                                                alt="Refresh Icon"
                                            />
                                            <HowItWorksSubheaderText>
                                                3. Present Confidently
                                            </HowItWorksSubheaderText>
                                            <HowItWorksText>
                                                Generate personalized proposals
                                                in under 20 seconds. Designed to
                                                impress even the most discerning
                                                clients.
                                            </HowItWorksText>
                                        </Card>
                                    </ScrollAnimation>
                                </CardContainer>
                                <CardInfoContainer>
                                    <ScrollAnimation delay={0.4}>
                                        <Card $grow>
                                            <Icon
                                                src={Shield}
                                                alt="Protected Icon"
                                            />
                                            <HowItWorksTextWithMargin>
                                                Coverflow is SOC2 (Service
                                                Organization Control Type 2)
                                                Compliant.
                                            </HowItWorksTextWithMargin>
                                        </Card>
                                    </ScrollAnimation>
                                </CardInfoContainer>
                            </Cards>
                        </ElementWithPseudoContainer>
                    </ContentWrapper>
                </DesktopWrapper>
                <TallDesktopWrapper>
                    <ElementWithPseudoContainer>
                        <SideBySide>
                            <DesktopLeftAlignedContentWrapper>
                                <HeaderWrapper>
                                    <Element name="Features">
                                        <WipeFromLeft>
                                            <SubHeader>
                                                Policy analysis in minutes, not
                                                hours.
                                            </SubHeader>
                                        </WipeFromLeft>
                                    </Element>
                                </HeaderWrapper>
                                <ImageWithPseudoContainer>
                                    <Example
                                        src={PolicyAnalysis}
                                        alt="Coverflow Example"
                                    />
                                </ImageWithPseudoContainer>
                                <ScrollAnimation delay={0.1}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon src={Search} alt="Search" />
                                            <MonospacedTextWrapper dark>
                                                <Text>
                                                    SPOT EVERY DIFFERENCE
                                                </Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Find details even your senior
                                            producers struggle with. Built-in
                                            support for every line.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                                <ScrollAnimation delay={0.2}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon src={Flag} alt="Flag" />
                                            <MonospacedTextWrapper dark>
                                                <Text>NEVER MISS A CHANGE</Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Instant flagging of new exclusions,
                                            endorsements, limit changes, and
                                            more.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                                <ScrollAnimation delay={0.3}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon src={Scales} alt="Scales" />
                                            <MonospacedTextWrapper dark>
                                                <Text>
                                                    MAKE RISK-FREE DECISIONS
                                                </Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Protection against E&O with fully
                                            comprehensive checks.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                            </DesktopLeftAlignedContentWrapper>
                            <DesktopLeftAlignedContentWrapper>
                                <DesktopExample src={PolicyAnalysis_Desktop} />
                                <SupplementaryImage
                                    src={PolicyAnalysis_2_Desktop}
                                    top="-100px"
                                    left="-50px"
                                />
                                <SupplementaryImage
                                    src={PolicyAnalysis_3_Desktop}
                                    right="25px"
                                    bottom="0px"
                                />
                            </DesktopLeftAlignedContentWrapper>
                        </SideBySide>
                    </ElementWithPseudoContainer>
                </TallDesktopWrapper>
                <DesktopWrapper>
                    <ElementWithPseudoContainer>
                        <SideBySide>
                            <DesktopLeftAlignedContentWrapper>
                                <DesktopExample src={GenerateProposalDesktop} />
                                <SupplementaryImage
                                    src={GenerateProposal_2_Desktop}
                                    top="0px"
                                    left="100px"
                                />
                                <SupplementaryImage
                                    src={Toast}
                                    left="25px"
                                    bottom="0px"
                                />
                            </DesktopLeftAlignedContentWrapper>
                            <DesktopLeftAlignedContentWrapper>
                                <HeaderWrapper>
                                    <WipeFromLeft>
                                        <SubHeader>
                                            Generate client-ready proposals in
                                            seconds
                                        </SubHeader>
                                    </WipeFromLeft>
                                </HeaderWrapper>
                                <ImageWithPseudoContainer>
                                    <Example
                                        src={GenerateProposal}
                                        alt="Coverflow Example"
                                    />
                                </ImageWithPseudoContainer>
                                <ScrollAnimation delay={0.1}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon src={File} alt="File" />
                                            <MonospacedTextWrapper dark>
                                                <Text>
                                                    PERSONALIZED AND
                                                    PROFESSIONAL
                                                </Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>

                                        <Text>
                                            Tailor-made templates that showcase
                                            your brand identity.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                                <ScrollAnimation delay={0.2}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon
                                                src={Highlighter}
                                                alt="Highlighter"
                                            />
                                            <MonospacedTextWrapper dark>
                                                <Text>
                                                    SPOTLIGHT THE RIGHT FEATURES
                                                </Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Dynamic comparison tables that
                                            highlight key coverage improvements.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                                <ScrollAnimation delay={0.3}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon src={Book} alt="Book" />
                                            <MonospacedTextWrapper dark>
                                                <Text>TELL THE FULL STORY</Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Automatic executive summaries of
                                            wider markets.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                            </DesktopLeftAlignedContentWrapper>
                        </SideBySide>
                    </ElementWithPseudoContainer>
                </DesktopWrapper>
                <DesktopWrapper>
                    <ElementWithPseudoContainer>
                        <SideBySide>
                            <DesktopLeftAlignedContentWrapper>
                                <HeaderWrapper>
                                    <WipeFromLeft>
                                        <SubHeader>
                                            One-click AMS updates
                                        </SubHeader>
                                    </WipeFromLeft>
                                </HeaderWrapper>
                                <ImageWithPseudoContainer>
                                    <Example
                                        src={AMSUpdates}
                                        alt="Coverflow Example"
                                    />
                                </ImageWithPseudoContainer>
                                <ScrollAnimation delay={0.1}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon
                                                src={Download}
                                                alt="Download"
                                            />
                                            <MonospacedTextWrapper dark>
                                                <Text>
                                                    IMPORT POLICIES
                                                    AUTOMATICALLY
                                                </Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Automatic coverage comparison across
                                            multiple policies.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                                <ScrollAnimation delay={0.2}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon src={List} alt="List" />
                                            <MonospacedTextWrapper dark>
                                                <Text>TRACK EVERY CHANGE</Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Auto-generated activities that show
                                            every single action taken.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                                <ScrollAnimation delay={0.3}>
                                    <TextWithPseudoContainer>
                                        <IconHeaderWrapper>
                                            <Icon src={Lock} alt="Lock" />
                                            <MonospacedTextWrapper dark>
                                                <Text>SECURE YOUR DATA</Text>
                                            </MonospacedTextWrapper>
                                        </IconHeaderWrapper>
                                        <Text>
                                            Total control to grant only the data
                                            you want us to see.
                                        </Text>
                                    </TextWithPseudoContainer>
                                </ScrollAnimation>
                            </DesktopLeftAlignedContentWrapper>
                            <DesktopLeftAlignedContentWrapper>
                                <DesktopExample src={AMSUpdates_Desktop} />
                                <SupplementaryImage
                                    src={AMSUpdates_2_Desktop}
                                    top="0px"
                                    left="25px"
                                />
                                <SupplementaryImage
                                    src={AMSUpdates_3_Desktop}
                                    right="25px"
                                    bottom="25px"
                                />
                            </DesktopLeftAlignedContentWrapper>
                        </SideBySide>
                    </ElementWithPseudoContainer>
                </DesktopWrapper>
            </Container>
            <SemiTransparentContainer
                backgroundColor={colors.UI.BACKGROUND.SECONDARY}
            >
                <DesktopWrapper>
                    <LeftAlignedContentWrapper>
                        <Element name="Questions">
                            <HeaderWrapper>
                                <WipeFromLeft>
                                    <SubHeader>Questions?</SubHeader>
                                </WipeFromLeft>
                            </HeaderWrapper>
                        </Element>
                        <QuestionsWrapper>
                            <ScrollAnimation delay={0.1}>
                                <LeftAlignedCard>
                                    <Icon src={QuestionBlue} alt="Question" />
                                    <HowItWorksSubheaderText>
                                        How accurate is the analysis?
                                    </HowItWorksSubheaderText>
                                    <HowItWorksText>
                                        99%. Every data point is manually
                                        verified by a human. That way, your team
                                        can save time double-checking and get
                                        back to servicing clients.
                                    </HowItWorksText>
                                </LeftAlignedCard>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.2}>
                                <LeftAlignedCard>
                                    <Icon src={QuestionGreen} alt="Question" />
                                    <HowItWorksSubheaderText>
                                        What about security and compliance?
                                    </HowItWorksSubheaderText>
                                    <HowItWorksText>
                                        Coverflow is SOC2 certified, and trusted
                                        daily by dozens of agencies with their
                                        policy data. Security is our top
                                        priority, which is why we employ both
                                        world-class security engineers and
                                        state-of-the-art encryption techniques.
                                    </HowItWorksText>
                                </LeftAlignedCard>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.3}>
                                <LeftAlignedCard>
                                    <Icon src={QuestionPurple} alt="Question" />
                                    <HowItWorksSubheaderText>
                                        How long does it take to get started?
                                    </HowItWorksSubheaderText>
                                    <HowItWorksText>
                                        15 minutes. Simply book a demo, and
                                        we’ll get you up and running the same
                                        day.
                                    </HowItWorksText>
                                </LeftAlignedCard>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.4}>
                                <LeftAlignedCard>
                                    <Icon src={QuestionOrange} alt="Question" />
                                    <HowItWorksSubheaderText>
                                        How much time will I save?
                                    </HowItWorksSubheaderText>
                                    <HowItWorksText>
                                        Our customers save an average of 8 hours
                                        per employee, every week.
                                    </HowItWorksText>
                                </LeftAlignedCard>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.5}>
                                <LeftAlignedCard>
                                    <Icon src={QuestionPink} alt="Question" />
                                    <HowItWorksSubheaderText>
                                        How is customer service?{' '}
                                    </HowItWorksSubheaderText>
                                    <HowItWorksText>
                                        We provide 24/7 customer service via
                                        email, text, and phone.
                                    </HowItWorksText>
                                </LeftAlignedCard>
                            </ScrollAnimation>
                        </QuestionsWrapper>
                    </LeftAlignedContentWrapper>
                </DesktopWrapper>
            </SemiTransparentContainer>
            <ReverseGradientContainer>
                <DesktopWrapper>
                    <ContentWrapper>
                        <HeaderWrapper>
                            <WipeFromLeft>
                                <Header center>
                                    Ready to start comparing quotes on easy
                                    mode?
                                </Header>
                            </WipeFromLeft>
                        </HeaderWrapper>
                        <TextWrapper>
                            <WipeFromLeft delay={0.2}>
                                <Text center>
                                    Generate spreadsheets and proposals for your
                                    policies in under 10 minutes.
                                </Text>
                            </WipeFromLeft>
                        </TextWrapper>
                        <ButtonWrapper>
                            <Button
                                handleClick={handleBookCall}
                                $secondary
                                text="Book Call"
                            />
                        </ButtonWrapper>
                    </ContentWrapper>
                    <ContentWrapper>
                        <CardContainer>
                        <ScrollAnimation delay={0.1} flex>
                                <Card>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                                        {/*<Image
                                            src={James}
                                            alt="James Testimonial"
                                        />*/}
                                        <Testimonial>
                                            Coverflow saves me hours of work every week.
                                        </Testimonial>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                            <NameText>CARMEN D.</NameText>
                                            <NameText>Commercial Lines Account Manager</NameText>
                                            <NameText>Armstrong & Associates</NameText>
                                        </div>
                                    </div>
                                </Card>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.2} flex>
                                <Card>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                                    {/*<Image
                                        src={Lauren}
                                        alt="Lauren Testimonial"
                                    />*/}
                                    <Testimonial>
                                        I love the detailed endorsement descriptions—they help me know exactly what to expect. 
                                    </Testimonial>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                        <NameText>DENISE D.</NameText>
                                        <NameText>Account Executive</NameText>
                                        <NameText>Bridgepoint Risk</NameText>
                                    </div>
                                    </div>
                                </Card>
                            </ScrollAnimation>
                            <ScrollAnimation delay={0.3} flex>
                                <Card>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                                    {/*<Image
                                        src={David}
                                        alt="David Testimonial"
                                    />*/}
                                    <Testimonial>
                                        There's no way I would get to everything without it.
                                    </Testimonial>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                        <NameText>AMY S.</NameText>
                                        <NameText>Senior Account Manager</NameText>
                                        <NameText>Portal Insurance</NameText>
                                    </div>
                                    </div>
                                </Card>
                            </ScrollAnimation>
                        </CardContainer>
                    </ContentWrapper>
                </DesktopWrapper>
            </ReverseGradientContainer>
            <GradientContainer backgroundColor={colors.UI.BACKGROUND.STANDARD}>
                <Spacer />
            </GradientContainer>
            <Container>
                <DesktopWrapper>
                    <Footer>
                        <FooterContainer>
                            <FooterButtonWrapper>
                                <Button
                                    text="Service Terms"
                                    $borderless
                                    $background="transparent"
                                    handleClick={() => {
                                        console.log('Service Terms');
                                    }}
                                />
                                <Button
                                    text="Privacy Policy"
                                    $borderless
                                    $background="transparent"
                                    handleClick={() => {
                                        console.log('Privacy Policy');
                                    }}
                                />
                            </FooterButtonWrapper>
                        </FooterContainer>
                        <Copywrite center>©Coverflow, 2024</Copywrite>
                    </Footer>
                </DesktopWrapper>
            </Container>
        </PageContainer>
    );
};

export default Home;
