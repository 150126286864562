import styled from 'styled-components';
import sizing from '../../designSystem/sizing';
import { HeaderProps } from './types';

export const Header = styled.h1<HeaderProps>`
    font-size: ${sizing.TEXT.H1};
    font-weight: 400;
    text-align: ${({ center }) => (center ? 'center' : 'auto')};
    @media (min-width: 768px) {
        font-size: ${sizing.TEXT.DESKTOP.H1};
        max-width: 720px;
    }
`;

export const SubHeader = styled.h2<HeaderProps>`
    font-size: ${sizing.TEXT.H1};
    text-align: ${({ center }) => (center ? 'center' : 'auto')};
    @media (min-width: 768px) {
        font-size: ${sizing.TEXT.DESKTOP.H2};
    }
`;

export const MediumHeader = styled.h2<HeaderProps>`
    font-size: 42px;
    text-align: ${({ center }) => (center ? 'center' : 'auto')};
    @media (min-width: 768px) {
        font-size: ${sizing.TEXT.DESKTOP.H2b};
    }
`;
