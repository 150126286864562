import React, { useEffect, useState } from 'react';
import { BurgerMenuProps } from './types';
import Button from '../Button';
import {
    BurgerContainer,
    ButtonWrapper,
    CallButtonWrapper,
    DesktopContainer,
    MenuOptionsContainer,
} from './styles';
import Bars from '../../assets/icons/Bars.svg';
import Close from '../../assets/icons/Close.svg';
import { scroller } from 'react-scroll';
import Links from '../../constants/links';

const BurgerMenu: React.FC<BurgerMenuProps> = ({ callback }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        callback(expanded);
    }, [expanded]);

    const handleHowItWorks = () => {
        setExpanded(false);
        scroller.scrollTo('How', {
            smooth: true,
            duration: 800,
            offset: 0,
        });
    };

    const handleFeatures = () => {
        setExpanded(false);
        scroller.scrollTo('Features', {
            smooth: true,
            duration: 800,
            offset: 0,
        });
    };

    const handleQuestions = () => {
        setExpanded(false);
        scroller.scrollTo('Questions', {
            smooth: true,
            duration: 800,
            offset: 0,
        });
    };
    const handleBlog = () => {
        setExpanded(false);
        scroller.scrollTo('Blog', {
            smooth: true,
            duration: 800,
            offset: 0,
        });
    };
    const handleBookCall = () => {
        window.open(Links.BOOK_CALL, '_blank', 'noopener,noreferrer');
    };

    const handleExpandMenu = () => {
        setExpanded((prev) => !prev);
    };

    const menuOptions = (
        <>
            <ButtonWrapper>
                <Button
                    text="How it Works"
                    handleClick={handleHowItWorks}
                    $borderless
                    $background="transparent"
                />
                <Button
                    text="Features"
                    handleClick={handleFeatures}
                    $borderless
                    $background="transparent"
                />
                <Button
                    text="Questions"
                    handleClick={handleQuestions}
                    $borderless
                    $background="transparent"
                />
                {/*<Button
                    text="Blog"
                    handleClick={handleBlog}
                    $borderless
                    $background="transparent"
                />*/}
            </ButtonWrapper>
            <CallButtonWrapper>
                <Button
                    text="Book Call"
                    handleClick={handleBookCall}
                    $secondary
                    $grow
                />
            </CallButtonWrapper>
        </>
    );

    return (
        <>
            <BurgerContainer>
                <Button
                    handleClick={handleExpandMenu}
                    iconLeft={expanded ? Close : Bars}
                    $borderless
                />
                {expanded && (
                    <MenuOptionsContainer>{menuOptions}</MenuOptionsContainer>
                )}
            </BurgerContainer>
            <DesktopContainer>{menuOptions}</DesktopContainer>
        </>
    );
};

export default BurgerMenu;
