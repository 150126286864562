import React, { useState, useEffect } from 'react';
import { GridContainer, Cell } from './styles';
import { GridProps, CellState, AnimationState } from './types';

const Grid: React.FC<GridProps> = ({
    cellSize,
    borderColor,
    borderWidth,
    colorFrequency,
    blankFrequency,
    coloredSquareColor,
}) => {
    const [rows, setRows] = useState(0);
    const [cols, setCols] = useState(0);
    const [gridData, setGridData] = useState<CellState[][]>([]);

    const calculateGridDimensions = () => {
        const newRows = Math.ceil(window.innerHeight / cellSize) + 2; // Extra rows for overflow
        const newCols = Math.ceil(window.innerWidth / cellSize) + 2; // Extra cols for overflow
        setRows(newRows);
        setCols(newCols);
    };

    const canColorCell = (
        grid: CellState[][],
        row: number,
        col: number
    ): boolean => {
        const neighbors = [
            [row - 1, col],
            [row + 1, col],
            [row, col - 1],
            [row, col + 1],
        ];

        return neighbors.every(([r, c]) => {
            return (
                r < 0 ||
                r >= rows ||
                c < 0 ||
                c >= cols ||
                !grid[r][c].isColored
            );
        });
    };

    const generateBlankGroup = (
        grid: CellState[][],
        startRow: number,
        startCol: number
    ) => {
        const stack = [[startRow, startCol]];
        const groupSize = Math.floor(Math.random() * (10 - 3 + 1)) + 3;
        console.log(groupSize);
        let count = 0;

        while (stack.length > 0 && count < groupSize) {
            const [row, col] = stack.pop()!;
            if (
                row >= 0 &&
                row < rows &&
                col >= 0 &&
                col < cols &&
                !grid[row][col].isBlank &&
                !grid[row][col].isColored
            ) {
                grid[row][col].isBlank = true;
                count++;

                stack.push([row - 1, col]);
                stack.push([row + 1, col]);
                stack.push([row, col - 1]);
                stack.push([row, col + 1]);
            }
        }
    };

    const generateGrid = () => {
        const grid: CellState[][] = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => ({
                isColored: false,
                isBlank: false,
                animationState: AnimationState.STANDARD,
            }))
        );

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                if (
                    !grid[row][col].isColored &&
                    Math.random() < colorFrequency
                ) {
                    if (canColorCell(grid, row, col)) {
                        grid[row][col].isColored = true;
                        grid[row][col].animationState = AnimationState.COLORED;
                    }
                } else if (
                    !grid[row][col].isColored &&
                    Math.random() < blankFrequency
                ) {
                    generateBlankGroup(grid, row, col);
                    grid[row][col].animationState = AnimationState.BLANK;
                }
            }
        }

        setGridData(grid);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const newGrid = gridData.map((row) =>
                row.map((cell) => {
                    const nextState =
                        cell.animationState === AnimationState.BLANK
                            ? AnimationState.COLORED
                            : cell.animationState === AnimationState.COLORED
                              ? AnimationState.STANDARD
                              : AnimationState.BLANK;

                    return {
                        ...cell,
                        animationState: nextState,
                        isColored: nextState === AnimationState.COLORED,
                        isBlank: nextState === AnimationState.BLANK,
                    };
                })
            );
            setGridData(newGrid);
        }, 1000);

        return () => clearInterval(interval);
    }, [gridData]);

    useEffect(() => {
        calculateGridDimensions();
        const handleResize = () => {
            calculateGridDimensions();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [cellSize]);

    useEffect(() => {
        if (rows > 0 && cols > 0) {
            generateGrid();
        }
    }, [rows, cols, colorFrequency, blankFrequency]);

    return (
        <GridContainer
            rows={rows}
            cols={cols}
            cellSize={cellSize}
            borderWidth={borderWidth}
        >
            {gridData.map((row, rowIndex) => {
                // const randomDuration = `${Math.random() * (1 - 3) + 3}s`;
                const randomDuration = '1s';
                return row.map((cell, colIndex) => (
                    <Cell
                        key={`${rowIndex}-${colIndex}`}
                        animationState={cell.animationState}
                        isColored={cell.isColored}
                        isBlank={cell.isBlank}
                        borderColor={borderColor}
                        borderWidth={borderWidth}
                        coloredSquareColor={coloredSquareColor}
                        transitionDuration={randomDuration}
                    />
                ));
            })}
        </GridContainer>
    );
};

export default Grid;
