import React from 'react';
import { ButtonProps } from './types';
import { ButtonContainer, IconLeft, IconRight, Text } from './styles';
import sizing from '../../designSystem/sizing';

const Button: React.FC<ButtonProps> = ({
    handleClick,
    iconLeft,
    text,
    iconRight,
    $secondary,
    $bold = true,
    $borderless,
    $grow,
    $color,
    disabled,
    $background,
    iconSize,
}) => (
    <ButtonContainer
        onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
        }}
        $secondary={$secondary}
        $bold={$bold}
        $borderless={$borderless}
        $grow={$grow}
        $square={!text && !iconRight}
        disabled={disabled}
        $background={$background}
    >
        {iconLeft && (
            <IconLeft
                src={iconLeft}
                alt="button icon left"
                height={iconSize ? iconSize : sizing.ICON.HEIGHT.STANDARD}
            />
        )}
        {text && (
            <Text
                $secondary={$secondary}
                $bold={$bold}
                $color={$color}
                $grow={$grow}
            >
                {text}
            </Text>
        )}
        {iconRight && <IconRight src={iconRight} alt="button icon right" />}
    </ButtonContainer>
);

export default Button;
