import styled from 'styled-components';
import colors from '../../designSystem/colors';
import sizing from '../../designSystem/sizing';
import { CardProps } from './types';

export const CardContainer = styled.div<CardProps>`
    display: flex;
    flex-direction: column;
    background-color: ${colors.UI.BACKGROUND.WHITE};
    border-radius: ${sizing.RADIUS.LARGE};
    align-items: center;
    padding: ${({ $lessPadding }) => $lessPadding ? `${sizing.SPACING.LARGE} ${sizing.SPACING.STANDARD}` : `${sizing.SPACING.DOUBLE_LARGE} ${sizing.SPACING.STANDARD};`}
    position: relative;
    margin: ${sizing.SPACING.DOUBLE_LARGE} 0 0;

    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        margin: ${sizing.SPACING.DOUBLE_LARGE} ${sizing.SPACING.STANDARD} 0;
        width: ${({ $grow }) => ($grow ? 'min-content' : '384px')};
        ${({ $grow }) =>
            $grow
                ? `
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            & > p {
                margin: 0 0 0 ${sizing.SPACING.STANDARD};
            }
        `
                : ''}
    }
`;
