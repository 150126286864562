import styled from 'styled-components';
import { SemiTransparentDivProps } from './types';
import sizing from '../../designSystem/sizing';

export const SemiTransparentDiv = styled.div<SemiTransparentDivProps>`
    display: block;
    width: 100%;
    height: min-content;
    background: ${({ backgroundColor }) => backgroundColor};
    padding-top: ${sizing.SPACING.STANDARD};

    & > * {
        opacity: 1;
    }
`;
