import styled from 'styled-components';
import sizing from '../../designSystem/sizing';

export const Image = styled.img`
    max-width: min-content;
`;

export const DesktopImage = styled(Image)`
    display: none;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        max-width: 1200px;
        margin: auto;
        display: block;
    }
`;

export const MobileImage = styled.img`
    max-width: min-content;
    @media (min-width: ${sizing.BREAKPOINT.MOBILE_MAX}) {
        display: none;
    }
`;
