export interface GridProps {
    cellSize: number;
    borderColor: string;
    borderWidth: number;
    colorFrequency: number;
    blankFrequency: number;
    coloredSquareColor: string;
}

export interface CellState {
    isColored: boolean;
    isBlank: boolean;
    animationState: AnimationState;
}

export enum AnimationState {
    BLANK = 'blank',
    COLORED = 'colored',
    STANDARD = 'standard',
}
